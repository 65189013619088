.EditUserProfileStyle {
  padding: 4% 2% 5% 2%;
  background: white;
  margin: 2% 2% 2% 2%;
  border-radius: 24px;
}
.personalInfoStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #50565c;
  padding: 2% 0 1% 0;
}
.dropZoneContainerStyle {
  width: 100%;
  margin: 5% 0 2% 0;
  background-color: #dff4ff;
  height: 200px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickHereStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9d9c9d;
  padding-top: 2%;
  cursor: pointer;
}
.clickHereContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.imageContainerStyle {
  width: 200px;
  height: 200px;
  border: 1px solid #dff4ff;
}
.uploadImageContainerStyle {
  display: flex;
}
.uploadButtonStyle {
  padding: 2% 20px 2% 20px;
  margin-left: 5%;
  border-radius: 14px;
}
.uploadSaveButton {
  padding: 0 3% 0 3%;
  border-radius: 14px;
}
