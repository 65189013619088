.monthContainerStyle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
}
.montlyStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding-right: 2%;
  color: #0059e7;
}
.montlyTitleStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  letter-spacing: -0.02em;
  padding-right: 2%;
  color: #0059e7;
}
.annualyStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  padding-left: 2%;
  color: #7b7b7b;
}
.permonthStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a4a5aa;
}
.metaPlanImageCotainer {
  display: flex;
  margin: 5% 0 5% 0;
}
.metaCopierPlan {
  height: 85px;
  width: 204px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 3%;
}
.planContainerStyle {
  border-radius: 40px;
  padding: 3%;
  margin: 2%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 30%;
  background-color: white;
}
.mailServiceStyle {
  display: flex;
  justify-content: space-between;
  padding: 1%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1c2348;
}
.planDivStyle {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5%;
}
.planGetStartedButtonStyle {
  width: 345px;
  height: 45px;
  border-radius: 15px;
  margin-top: 4%;
  font-family: "Poppins";
}
.planTelegramGetStartedButton {
  width: 345px;
  height: 45px;
  border-radius: 15px;
  margin-top: 4%;
  background-color: white;
  border: 1px solid #0059e7;
  color: #0059e7;
  font-family: "Poppins";
}
.montlytelegramStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  letter-spacing: -0.02em;
  padding-right: 2%;
  color: #4e4e4e;
}
