.createMasterRevampRadio {
  display: flex;
}
.accountContainerRevampStyle {
  display: flex;
  align-items: center;
}
.masterRevampSecondContainer {
  padding: 3% 0 3% 7%;
}
.createMasterAccoutRevampTextStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #505050;
  padding-top: 2%;
}
.masterinputFieldContainerRevampRight {
  padding-top: 3%;
}
.brokenConfigRevampStyle {
  padding-top: 2%;
  width: 100%;
}
.masterCreateEditrevampButtonStyle {
  padding: 0 20px 0 20px;
  height: 40px;
  background: #1c8bfe;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
}
.brokerrevampModal {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.AccountHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 2%;
}
.masterAccountInputRevampStyle {
  height: 50px;
  border-radius: 24px;
  width: 100%;
  margin-top: 2%;
}
.masterAccountInputRevampStyle::placeholder {
  font-family: "Poppins";
}
.createMasterAccoutTextStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #505050;
  width: 50%;
  padding-top: 2%;
  padding-bottom: 3%;
}
.AccoutTypeStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #4e4e4e;
  padding: 1% 0 1% 0;
}
.AccoutTypeRadioStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #585858;
  padding-left: 3%;
  align-items: center;
}
.createAccountTypeContainerStyle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 3%;
}
.accountContainerStyle {
  display: flex;
  width: 50%;
  align-items: center;
}
.masterAccountInputStyle {
  height: 40px;
  border-radius: 24px;
  width: 80%;
  margin-top: 1%;
}
.cardHolderNameStyle {
  height: 40px;
  border-radius: 24px;
  width: 80%;
  margin-top: 1%;
}
.cardHolderNameStyle::placeholder {
  color: #757575;
  font-weight: 500;
}
.masterAccountInputStyle::placeholder {
  font-family: "Poppins";
}
.masterinputFieldContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 1%;
}
.masterinputFieldContainerLeft {
  width: 50%;
  padding-top: 1%;
}
.masterinputFieldContainerRight {
  width: 50%;
  padding-top: 1%;
}
.symbolMappingContainerStyle {
  background: #f1f8ff;
  border-radius: 14px;
  padding: 2%;
  margin: 2% 0 2% 0;
}
.symbolMappingHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #0059e7;
}
.symbolMappingParaStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2b3650;
  padding-top: 1%;
}
.symbolMappingParaTwoStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6c6c6c;
  padding-top: 1%;
  padding-bottom: 2%;
}
.masterinputSymbolFieldContainer {
  padding-top: 2%;
}
.masterAccountFullWidthInputStyle {
  height: 50px;
  border-radius: 24px;
  width: 95%;
  margin-top: 2%;
}
.brokenConfigStyle {
  padding-top: 3%;
  display: flex;
  justify-content: space-between;
}
.brokenConfigSelectStyle {
  border-radius: 24px !important;
}
.createAccountStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  color: #4e4e4e;
  cursor: pointer;
}
.brokenServerContainerStyle {
  padding: 3% 0 3% 0;
}
.brokenServerStyle {
  padding: 2% 0 2% 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #4e4e4e;
}
.fileUploadStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4e4e4e;
  padding: 1% 0 1% 0;
}
.masterCreateEditButtonStyle {
  height: 48px;
  background: #1c8bfe;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.masterCreateSaveButtonStyle {
  width: 118px;
  height: 48px;
  background: #f0f1f1;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #2b3650;
  margin-left: 13%;
}
.masterEditButtonContainer {
  display: flex;
  padding-top: 5%;
}
.accountdetailStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #0575e6;
  width: 40%;
}
.MasterConnectedButtonStyle {
  width: 182px;
  height: 48px;
  background: #61de7e;
  opacity: 0.8;
  border-radius: 18px;
  margin-top: 3%;
}
.tradeContainerStyle {
  display: flex;
  justify-content: space-between;
  padding: 3% 16px 0 16px;
}
.masterDateDownloadcontainer {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  align-items: center;
}
.mastercontainer {
  display: flex;
}
.dateHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.0983px;
  color: #4e4e4e;
  display: flex;
  align-items: center;
}
.dateStyle {
  margin: 0 1% 0 1%;
}
.tofromStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13.0983px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #4e4e4e;
  padding: 0 20px 0 20px;
}
.custom-select .ant-select-selector {
  border: 1px solid #ccc;
  height: 50px !important;
  border-radius: 18px !important;
  color: #1c8bfe !important;
  display: flex;
  align-items: center;
}

.custom-select
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #007bff !important;
  color: #fff;
}

.custom-select .ant-select-selection-placeholder {
  color: #1c8bfe !important;
}
.custom-select .ant-select-arrow {
  color: #1c8bfe !important;
}
.custom-select-broker .ant-select-selector {
  border: 1px solid #ccc;
  height: 40px !important;
  border-radius: 25px !important;
  display: flex;
  align-items: center;
}
.custom-select-broker::placeholder {
  color: #757575 !important;
}
.tradeTable tr:nth-child(odd) {
  background: white;
}
.tradeTable tr:nth-child(even) {
  background: #f1f8ff;
  border-radius: 14px;
}
.tradeTable th {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #4e4e4e !important;
  background-color: white !important;
}
.tradeTable td {
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #4e4e4e !important;
}
.tradeTable thead {
  border-bottom: 2px solid red !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #a5a2b8;
  border-style: dashed;
  width: 100%;
  border-radius: 20px;
  height: 50px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}
.titleInformationIcon {
  padding-left: 3px;
  padding-top: 10px;
  padding-right: 5px;
}
