.masterSaveEditrevampButtonStyle {
  padding: 0 20px 0 20px;
  height: 48px;
  color: #1c8bfe;
  background-color: white;
  border-radius: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-top: 2%;
  border: 1px solid #1c8bfe;
  width: 150px;
}
.editMasterRevampButtonContainer {
  display: flex;
}
.editMasterHeading {
  padding-top: 2%;
}
