.excludeSymbolContainer {
  display: flex;
  justify-content: center;
}
.excludeButtonStyle {
  padding: 0px 20px 0 20px;
  border-radius: 12px;
  height: 37px;
  margin: 9% 0 2% 20px;
  font-family: "Poppins";
}
.symbolTextStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #1c8bfe;
  margin-right: 10px;
}
.symbolContainerStyle {
  border: 1px solid #e1e6eb;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 10px;
  height: 40px;
  padding: 0 2%;
}
.symbolFieldStyle {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
}
.asyncSynbolnputFieldContainer {
  padding: 2% 4% 5% 4%;
}
.SymbolSettingHeadingContainer {
  padding: 3% 0 0 4%;
}
.asyncSymbolsInputFieldContainer {
  padding: 2% 12% 0 12%;
}
