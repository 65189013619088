.contactUsFormStyle {
  width: 80%;
  background-color: white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 3% 0 3% 3%;
  margin: 5% 0 0 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.contactUsSecondContainerStyle {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 3% 0 3% 3%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-bottom: 4%;
  flex-direction: column;
  background-image: url(../../../assets/images/ContactFooter2.svg);
}
.contactUsSecContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsFooterHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  color: #1984ff;
  margin-bottom: 4%;
}
.openingHourTimeStyle {
  display: flex;
}
.openingHourContainerStyle {
  display: flex;
  flex-direction: column;
  padding-left: 3%;
  line-height: 2;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #4e4e4e;
}
.contactUsMainStyle {
  display: flex;
  flex-direction: column;
}
.contactFooterContainerStyle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footerImageStyle {
  padding-left: -75%;
  width: 80%;
}
.feelFreeTextStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  color: #4e4e4e;
}
