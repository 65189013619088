.RiskSettingHeadingContainer {
  padding: 3% 0 3% 4%;
}
.asyncRiskInputFieldContainer {
  padding: 2% 12% 5% 12%;
}
.AccountRiskInputStyle {
  height: 40px;
  border-radius: 24px;
  width: 100%;
  margin: 2% 0 2% 0;
}
.riskamanagmentUpdateButtonContainer {
  padding: 0 0 5% 13%;
}
