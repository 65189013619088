.automateLicenseStyle {
  width: 100%;
  height: 150px;
  border-radius: 12px;
  margin-top: 2%;
  background: #f1f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #455a64;
}
.automateTextStyle {
  padding: 0 2% 0 2%;
}
