.metaAdvanceMainStyle {
  background: #e9f6fa;
  padding: 4%;
  display: flex;
}
.vpsHeadingStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #006fba;
  padding: 4% 0 4% 0;
}
.vpsParaStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #68718b;
  width: 65%;
}
.vpsContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 200px;
}
.vpsStyle {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.advanceSecContainerStyle {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
