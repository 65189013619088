.homeNavMainStyle {
  display: flex;
  justify-content: space-between;
}
.homeNavStyle {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: space-around;
  cursor: pointer;
}
.homeNavButtonStyle {
  display: flex;
  align-items: center;
  width: 21%;
  justify-content: space-around;
}
.activeNav {
  color: #1c8bfe !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbarContantStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #4e4e4e;
}
.LoginNavButtonStyle {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #e4f2ff;
  color: black;
  margin: 0 5% 0 1%;
  border-color: 1px solid #1c8bfe;
  font-family: "Poppins";
}
.SignupNavButtonStyle {
  height: 40px;
  border-radius: 20px;
  background-color: #0059e7;
  color: white;
  font-family: "Poppins";
}
@media screen and (max-width: 1200px) and (min-height: 600px) {
  .homeNavStyle {
    width: 60%;
  }
}
