.termAndCondition {
  padding: 4% 2% 2% 2%;
  background: white;
  height: 85vh;
  margin: 2% 2% 2% 2%;
  border-radius: 24px;
}
.termAndConditionHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-transform: capitalize;
  color: #0575e6;
}
.termAndConditionParaStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #6c6c6c;
}
.agreementStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  padding: 2% 0 2% 0;

  display: flex;
  align-items: center;

  color: #50565c;
}
