.pricingQuestionStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #4e4e4e;
}
.pricingFaqContainerStyle {
  display: flex;
}
.pricingFaqFirstContainerStyle {
  width: 50%;
  margin: 5%;
}
.faqQueContainerStyle {
  padding: 4% 0 4% 0;
}
.freeTrainingContainerStyle {
  display: flex;
  justify-content: space-around;
}
.pricingDiscordStyle {
  margin: 0 4% 4% 4%;
}
.pricingdiscordMainStyle {
  margin: 4% 0 1% 0;
  background: #e9f6fa;
  border-radius: 12px;
}
.pricingBackground {
  background-image: url(../../../assets/images/pricingBackground.svg);
  background-repeat: no-repeat;
}
