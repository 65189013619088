.footerMainStyle {
  background-color: #0059e7;
  font-family: "Poppins";
  color: white;
  padding: 4% 2% 2% 2%;
}
.footerFirstContainerStyle {
  display: flex;
}
.footerButtonStyle {
  width: 150px;
  border-radius: 12px;
  height: 45px;
  font-family: "Poppins";
}
.FooterIconcontainerStyle {
  display: flex;
  justify-content: space-between;
  width: 43%;
}
.footerHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 129%;
  text-transform: capitalize;
  color: #fcfdff;
}
.footerfirstContainerStyle {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35vh;
}
.footerSecContainerStyle {
  width: 15%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 20vh;
}
.emailFooterContainerStyle {
  background-color: #0059e7;
  color: white;
  font-family: "Poppins";
}
.emailFooterContainerStyle::placeholder {
  background-color: #0059e7;
  color: white;
  font-family: "Poppins";
}

.emailFooterContainerStyle .ant-input::placeholder .emailFooterContainerStyle {
  color: white;
  border: none !important;
  border-color: #0059e7;
  font-family: "Poppins";
}
.footerEndContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40vh;
}
.footerSecurityStyle {
  display: flex;
  padding: 1%;
  align-items: center;
  justify-content: center;
}
.emailBorderFooterStyle {
  height: 50px;
  border: 1px solid white;
  border-radius: 15px;
}
.footerSubscribeButtonStyle {
  background-color: #0059e7;
  border: none;
  color: #fcfdff;
  font-family: "Poppins";
}
