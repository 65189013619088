.accountSyncSettingHeadingContainer {
  display: flex;
  flex-direction: column;
}
.accountSettingHeadingContainerStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2%;
  padding-right: 2%;
}
.asyncSettingTabs .ant-tabs-nav {
  background-color: #e0eeff;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  padding: 0 25px 0 25px;
  margin-bottom: 0px;
}
.asyncSettingTabs .ant-tabs-tab-btn .ant-tabs-tab-active {
  color: red;
}
.asyncSettingTabs .ant-tabs-tab-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #959595;
}
.accountSettingTabContainerMain {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3%;
}
