.platformMetaContainerStyle {
  width: 49%;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: space-around;
  align-items: center;
  padding-top: 4%;
}
.platformLionContainerStyle {
  width: 49%;
  display: flex;
  flex-direction: column;
  height: 450px;
  justify-content: space-around;
  align-items: center;
  padding-top: 4%;
}
.minStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  color: #1c8bfe;
  padding-left: 1%;
}
.min30style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  color: #fc6162;
  padding-left: 1%;
}
.speedContainerStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vl {
  border-left: 2px solid #e0e4f5;
  height: 250px;
}
.platformMainContainerStyle {
  display: flex;
  align-items: center;
}
