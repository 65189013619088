.HomePageQuestionMainStyle {
  background: #e9f6fa;
  padding: 3%;
}
.faqTextStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 168%;
  color: #68718b;
}
