.GeneralSettingMainContainer {
  background-color: white;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.generalSettingStatusContainer {
  display: flex;
  padding-top: 2%;
}
.generalSettingStatusStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #1c8bfe;
  margin-right: 10px;
}
.generalSettingHeadingContainer {
  display: flex;
  align-items: center;
  padding: 4% 0 2% 6%;
}
.generalSettingSwitchContainer {
  width: 20px;
}
.generalSettingInputFieldContainer {
  display: flex;
  flex-direction: column;
  padding: 0 12% 10% 12%;
}
