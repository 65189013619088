.stripeInputField {
  border-radius: 24px;
  border: 1px solid #ced4da;
  color: red !important;
  width: 80%;
  margin-top: 2%;
  background-color: white;
  padding-top: 12px;
  padding-left: 5px;
  padding-bottom: 12px;
  font-family: "Poppins";
  border-color: #d7d7d7;
}
.stripeInputField::placeholder {
  padding-top: 10px !important;
  color: #d7d7d7 !important;
}
.cardElementLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4e4e4e;
}
.cardNumberStyle {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 3%;
}
.paymentInputField {
  height: 50px;
  border-radius: 24px;
  width: 100%;
  margin-top: 2%;
  font-family: "Poppins";
}
.paymentInputField::placeholder {
  color: #495057;
}
.cardPaymentContainer {
  width: 45%;
  margin-bottom: 2%;
}
.cardHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #505050;
  display: flex;
  padding: 3% 0 0 2%;
}
.metaButtonPyamentCrad {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
}
