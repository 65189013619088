.paymentMethodStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #455a64;
  text-align: right;
}
.metabillingButtonStyle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 41px;
  border-radius: 14px;
  justify-content: space-evenly;
}
.metRenewalButtonStyle {
  display: flex;
  align-items: center;
  width: 80%;
  height: 41px;
  border-radius: 14px;
  justify-content: space-evenly;
}
.nextPaymentDateStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #1c8bfe;
  padding-left: 2%;
}
.accountplatformStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 38%;
}
.typeStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #4e4e4e;
}
.billingHeaderStyle {
  display: flex;
  justify-content: space-between;
}
