.contactFormStyle {
  width: 70%;
  background-color: white;
  border-radius: 12px;
  padding: 3% 0 3% 3%;
  margin: 5% 0 5% 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.contactContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Poppins";
}
.messageContainerStyle {
  margin: 3% 0 3% 0;
}
.contactTagStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #4e4e4e;
}
.solutionContainer {
  display: flex;
}

.helpContainer {
  display: flex;
  justify-content: space-around;
  padding: 2% 0 4% 0;
}

.helpTextstyle {
  padding-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #4e4e4e;
}
.needHelpContainer {
  display: flex;
  justify-content: space-around;
}
.needHelpDivStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
}
.needHelpIconDivStyle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 28%;
}
