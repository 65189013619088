.AiHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 129%;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #4e4e4e;
}
.AiParaStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145.5%;
  color: #4e4e4e;
}
.AiContainerStyle {
  width: 45%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.freeTrialButtonStyle {
  height: 50px;
  border-radius: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: 200px;
}
.trailButtonContainer {
  display: flex;
  width: 77%;
  justify-content: space-between;
}
.demoButtonStyle {
  height: 50px;
  border-radius: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0059e7;
  width: 200px;
  background-color: white;
  border-color: #0059e7;
}
.homePageMainStyle {
  margin: 5% 4% 3% 4%;
}
.homePageMainStyle {
}
.HomePageMainImageStyle {
  width: 90%;
}
.homePageFirstContainerStyle {
  display: flex;
  justify-content: space-between;
}
.HomePageMainImageContainerStyle {
  display: flex;
  justify-content: flex-end;
}
.HomePageGraphContainerImageStyle {
  margin-top: -120px;
  margin-left: -130px;
}
.workContainerStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #7b7b7b;
  padding: 2% 0 5% 0;
}
.homepageSecondContainer {
  margin-top: 6%;
}
.layersDivStyle {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 1100px;
}
.layersImageContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.discordMainStyle {
  margin: 4% 0 4% 0;
  background: #e9f6fa;
}
.discordContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 171px;
  justify-content: space-around;
  padding: 3% 0 3% 0;
}
.communitySubscribeButtonStyle {
  height: 50px;
  border-radius: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  width: 300px;
}
