.masterAccountHeading {
  font-family: "Poppins";
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  text-transform: capitalize;
  color: #0575e6;
}
.masterLinkStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #455a64;
}
.clickhereStyle {
  cursor: pointer;
  color: #0575e6;
}
.masterHeadingContainerStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3%;
  padding-right: 2%;
}
.marketplaceListStyle {
  padding: 0 2% 2% 2%;
  background: #f1f8ff;
  height: 92vh;
}
.metacsvButtonStyle {
  display: flex;
  align-items: center;
  width: 60%;
  height: 41px;
  border-radius: 14px;
  justify-content: space-evenly;
}
.tableHeaderStyle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #505050;
  padding-bottom: 1%;
  width: 40%;
}
.listTableStyle {
  border-radius: 24px;
}
.listTableStyle tr:nth-child(odd) {
  background: white;
}
.listTableStyle tr:nth-child(even) {
  background: #f1f8ff;
  border-radius: 14px;
}
thead {
  background-color: white !important;
}
.tableBoxStyle {
  background-color: white;
  padding: 1% 2% 5% 2%;
  border-radius: 24px;
  margin: 2% 20% 15% 20%;
  margin-bottom: 10%;
}
.tableListBoxStyle {
  background-color: white;
  padding: 1% 2% 5% 2%;
  border-radius: 24px;
  margin-top: 2%;
}
.listDeleteStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #263238;
  justify-content: center;
}
.hello {
  background-color: #0575e6;
}
.centered-modal-footer .ant-modal-footer {
  text-align: center;
}
.centered-modal-footer .ant-btn-default {
  background-color: #dbe5ff !important;
  border: none !important;
  padding: 0 6% 0 6%;
  color: #034bff;
}
.centered-modal-footer .ant-btn-primary {
  padding: 0 6% 0 6%;
  border: none !important;
}
.customColumnHeaderStyle {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 29px;
  color: #4e4e4e;
}
.customRowStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #6c6c6c;
}
.backMasterContaierStyle {
  display: flex;
  align-items: center;
}
:where(.css-dev-only-do-not-override-1me4733).ant-pagination {
  font-size: small;
}
