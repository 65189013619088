.sideBarMainStyle {
  width: 20%;
  height: 100vh;
  background-color: #0575e6;
  color: #ffffff;
  font-family: Poppins !important;
  position: sticky;
  top: 0;
}
.sideBarMainOpenStyle {
  width: 5%;
  height: 100vh;
  background-color: #0575e6;
  color: #ffffff;
  font-family: Poppins !important;
  position: sticky;
  top: 0;
}
.logoContainerStyle {
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.hrStyle {
  border: 1px solid #47a1fe;
}
.profileImageContainer {
  border-radius: 15px;
  border: 1px solid white;
  margin-right: 5px;
}
.sideBarCpntentStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.userNameStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.viewProfileStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.profileContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 72%;
}
.profileOpenContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 85%;
}
.profileImageMainContainerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SidebarContentStyle {
  display: flex;
  padding: 6% 0% 4% 1%;
  margin: 1% 0 1% 1%;
}
.SidebarContentStyle:hover {
  display: flex;
  background-color: #1c8bfe;
  border-radius: 10px;
  cursor: pointer;
}
.SidebarLoguttStyle {
  display: flex;
  align-items: center;
  background-color: #1c8bfe;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  margin: 0 5% 0 5%;
  height: 40px;
}
.SidebarLoguttOpenStyle {
  display: flex;
  align-items: center;
  background-color: #1c8bfe;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  margin: 0 20% 0 15%;
  height: 40px;
}
.sideBarTextStyle {
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.active {
  display: flex;
  background-color: #1c8bfe;
  border-radius: 10px;
  cursor: pointer;
}
.sidebarIconStyle {
  padding: 0px 3% 0px 3%;
  width: 30px;
}
.sidebarIconOpenStyle {
  padding: 5px 10px 0px 10px;
}
.sidebarLogoutIconStyle {
  padding: 10px 10px 0px 0px;
}
.sidebarLogoutIconOpenStyle {
  padding: 10px 0px 0px 0px;
}
.sidebarContentMainContainerStyle {
  display: flex;
  flex-direction: column;
  padding: 10% 7% 10% 7%;
  justify-content: space-between;
  padding-top: 10%;
  height: 45vh;
}
.sidebarContentMainOpenContainerStyle {
  display: flex;
  flex-direction: column;
  padding: 10% 7% 10% 7%;
  justify-content: space-between;
  padding-top: 10%;
  align-items: center;
  height: 45vh;
}
.sideBarStyle {
  display: flex;
  width: 100%;
}
.sidebarMainComponentStyle {
  overflow-y: auto;
  margin-top: 8vh;
  position: absolute;
  height: 92vh;
  width: 80%;
  background: #f1f8ff;
}
.sidebarMainOpenComponentStyle {
  overflow-y: auto;
  margin-top: 8vh;
  position: absolute;
  height: 92vh;
  width: 95%;
  background: #f1f8ff;
}
.sidebarContentLogoutContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
}
