.expandRowStyle tr:nth-child(odd) {
  background: white;
}
.expandRowStyle tr:nth-child(even) {
  background: #dff4ff;
  border-radius: 24px;
}
.expandInnerRowStyle tr:nth-child(even) {
  background: white;
}
.expandInnerRowStyle tr:nth-child(odd) {
  background: #dff4ff;
  border-radius: 24px;
}
.expandInnerRowStyle3 tr:nth-child(odd) {
  background: white;
}
.expandInnerRowStyle3 tr:nth-child(even) {
  background: #dff4ff;
  border-radius: 24px;
}
.accountSyncmodalQuestionStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1c8bfe;
  text-align: center;
  justify-content: center;
}
.accountSyncModalDescriptionStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #455a64;
  justify-content: center;
  text-align: center;
}
.disconnectModalStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.spinClass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
}
.spinSymbolClass {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.disconectButtonStyle {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  border-radius: 12px;
}
.backButtonStyle {
  border-radius: 8px;
  margin-right: 10px;
  height: 25px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -6px;
}
.editAccountHeadingContainerStyle {
  display: flex;
  flex-direction: column;
}
.editSubscriberHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}
.createAccountSyncContainerStyle {
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}
.masterAccountSyncInputStyle {
  height: 50px;
  border-radius: 20px;
  margin-top: 1%;
}
.masterAccountSyncInputNumStyle {
  height: 50px;
  border-radius: 20px;
  margin-top: 1%;
  width: 100%;
  display: flex;
  align-items: center;
}
.masterAccountSyncInputNumStyle {
  border-radius: 20px !important;
  border-start-end-radius: 26px !important;
  border-end-end-radius: 26px !important;
}
.ant-input-number-handler-up-inner::after {
  content: "+";
}

.ant-input-number-handler-down-inner::after {
  content: "-";
}

.ant-input-number-handler-up,
.ant-input-number-handler-down {
  border: none;
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: transparent;
}

.custom-select-accountsync .ant-select-selector {
  border: 1px solid #ccc;
  height: 50px !important;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  width: 300px;
}
.accountsyncSubHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4e4e4e;
  margin-bottom: 1%;
}
.editSaveChangesButtonStyles {
  display: flex;
  padding-top: 3%;
}
.revertButtonStyle {
  background-color: white;
  color: #1c8bfe;
  border-color: #1c8bfe;
  height: 45px;
  padding: 4px 7% 4px 7%;
  font-family: Poppins !important;
  border-radius: 14px;
  margin-left: 20%;
}
