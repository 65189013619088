.helloStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 47px;
  text-transform: capitalize;

  color: #0575e6;
}
.helloNameContainerStyle {
  display: flex;
  justify-content: space-between;
}
.dashboardStyle {
  margin: 3%;
}
.dashboardImageContainer {
  width: 100%;
  border-radius: 12px;
  margin-top: 2%;
}
.coinContainerStyle {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
}
.dashboardTableContainer {
  margin-top: 2%;
}
.graphHeadingStyle {
  display: flex;
  justify-content: space-between;
  padding: 0 4% 0 4%;
}
.totalGrowthStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #0059e7;
}
.graphContainerStyle {
  background-color: white;
  padding: 2%;
  border-radius: 12px;
  margin-top: 2%;
  width: 45%;
}
.graphContainer {
  display: flex;
  justify-content: space-between;
}
.dashboardDatePickerStyle {
  color: #0059e7;
  border-color: #0059e7;
  height: 46px;
  border-radius: 12px;
  background-color: #f1f8ff;
  cursor: pointer;
}
.dashboardDatePickerStyle::placeholder {
  color: #0059e7;
}
.dashboardtradingTable {
  display: flex;
  justify-content: space-between;
  width: 50%;
  border-top: 1px #cecdce solid;
  background-color: white;
  height: 45px;
  align-items: center;
  padding: 0 15px 0 15px;
  margin-right: 3px;
}
.dashboardtradingTableleft {
  display: flex;
  justify-content: space-between;
  width: 50%;
  border-top: 1px #cecdce solid;
  border-right: 1px #cecdce solid;
  background-color: white;
  height: 45px;
  align-items: center;
  padding: 0 15px 0 15px;
  margin-left: 3px;
}
.tabStyle {
  margin-top: 2%;
  background-color: white;
  border-radius: 12px;
  padding: 1% 0 0.5% 0;
  border: 1px #cecdce solid;
}
.tabContentStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16.2616px;
  line-height: 26px;
  color: #959595;
  width: 50%;
}

.listTableStyle {
  border: none !important;
}

.dashboardTabStyle
  .ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  border-radius: 5px;
  color: black !important;
}
.css-dev-only-do-not-override-1me4733.ant-tabs .ant-tabs-tab {
  border: none !important;
}
:where(.css-dev-only-do-not-override-1me4733).ant-tabs-card
  > .ant-tabs-nav
  .ant-tabs-tab-active {
  background-color: #f1f8ff !important;
  height: 40px;
  color: black !important;
}
:where(.css-dev-only-do-not-override-1me4733).ant-tabs-top
  > .ant-tabs-nav::before {
  border: none !important;
}
:where(.css-dev-only-do-not-override-1me4733).ant-tabs > .ant-tabs-nav {
  padding-left: 5px;
}
:where(.css-dev-only-do-not-override-1me4733).ant-picker
  .ant-picker-input
  > input {
  color: #0059e7 !important;
}
.dashboardAccountName .ant-select-selector {
  border: 1px solid #ccc;
  height: 50px !important;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  width: 200px !important;
  background-color: #0059e7 !important ;
  color: white !important;
}
.dashboardAccountName .ant-select-arrow {
  color: white !important;
}

.coinStyle {
  width: 22%;
  height: 140px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1%;
  padding: 0 1% 0 1%;
}
.accountBalFirstContainerStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.accountBalContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.accountBalanceStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  font-feature-settings: "salt" on, "liga" off;
  color: #1c8bfe;
}
.BalanceStyle {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #1c8bfe;
}
.vectorIconStyle {
  display: flex;
  justify-content: flex-end;
}
.apexcharts-toolbar {
  display: none !important;
}
