.creteAccountSyncButtonStyle {
  margin: 6% 0 2% 0;
  border-radius: 14px;
  width: 180px;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}
.createAccountRevampContainer {
  padding: 0 12% 0 12%;
}
.AccountSyncRevampInputStyle {
  height: 40px;
  border-radius: 24px;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 2%;
}
.AccountSyncRevampInputStyle::placeholder {
  font-family: "Poppins";
}
