.navbarMainStyle {
  height: 70px;
  background-color: #1c8bfe;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  top: 0;
  position: fixed;
  z-index: 1;
  margin-bottom: 3%;
}
.searchInputStyle {
  width: 25%;
  border-radius: 15px;
}
.navIconStyle {
  padding: 0 3% 0 20px;
  cursor: pointer;
}
.inputSearchStyle::placeholder {
  color: #1c8bfe !important;
}

.profileInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

