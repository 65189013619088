.homeourfeatureStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 129%;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #4e4e4e;
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.homefeatureParaStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  margin-top: 1%;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a5a2b8;
}
.metaTraderCopierContainerStyle {
  background: #f5faff;
  border-radius: 12px;
  margin-top: 5%;
  padding: 5% 3% 5% 3%;
}
.metaCopierHeadingStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 129%;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #4e4e4e;
  padding-left: 1%;
}
.copierLogoContainerStyle {
  display: flex;
  align-items: center;
}
.featureNavStyle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.activeFeatureNav {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 105%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #0575e5;
}
.featurenavbarContantStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 105%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  color: #7b7b7b;
  cursor: pointer;
}
.fastExecutionContainerStyle {
  width: 468px;
  background: #e8f3ff;
  border-radius: 12px;
  margin: 2%;
}
.fastExecutionContentStyle {
  padding: 5%;
}
.fastTextStyle {
  padding: 1% 0 4% 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 129%;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  color: #4e4e4e;
}
.fastParaStyle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #455a64;
  padding-bottom: 7%;
}
.featureHomePageImageStyle {
  width: 512px;
  height: 370px;
}
.fastImageContainerStyle {
  display: flex;
  justify-content: space-between;
}
